@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./layout/Font/Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}



body {
  background-color: #FFF;
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-display="flex"] {
  display: flex;
  flex-wrap: wrap !important;
}

.filtro > button > span{
  font-size: 12px;
  color: #727272;
  font-weight: 500;
}


.filtro {
  font-size: 12px;
  color: #727272;
  font-weight: 500;
}

.filtro.ant-radio-button-wrapper {
  height: 30px;
  font-size: 12px;
  font-weight: 500;
  color: #727272;

  border-inline-start-width: 1px !important;
  border-radius: 5px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  border: 1px solid #3AB97E !important;
}

.filtro.ant-radio-button-wrapper:hover {
  color: #FFF;
  border-color: #3AB97E !important;
  background-color: #3AB97E;
}


.filtro.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  /* max-height: 30px; */
  min-height: 30px;
  color: #fff;
  border: 1px solid rgb(240, 173, 78) !important;
  border-color: rgb(240, 173, 78) !important;
  background: rgb(240, 173, 78);
}

.filtro.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover{
  border-color: #3AB97E !important;
}

.filtro.ant-radio-button-wrapper.ant-radio-button-wrapper-checked::before {
  border-color: transparent !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent !important;
}

.dataTables_wrapper .dt-bootstrap4{
  width: 100%;
}

.sc-fwEaKe.fQCDvO{
  width: 100%;
}

button:focus{
  outline: none !important;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled{
  background: rgba(0, 0, 0, 0.04);
  color: #727272;
  border-color: transparent !important;
}

.filtro.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:hover{
  background-color: rgba(0, 0, 0, 0.04) !important;
  color: #727272;
  border-color: transparent !important;
}

a {
  color: #3AB97E !important;
}
a:hover {
   color: rgb(2, 89, 64) !important;
}

.ArrowUpEmailContainer{
  position: fixed;
  right: 3%;
  bottom: 5%;

  z-index: 1;

  font-size: 2.5em;
}

.ArrowUpEmailContainer svg{
  background-color: #FFF;
  border-radius: 100%;
}

.ArrowUpEmailContainer svg:hover{
  background-color: #3AB97E;
  border-radius: 100%;
}