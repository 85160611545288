.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.image1 {
  width: 150px;
  margin-right: 35px
}

.titulo-conteudo {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 0px;
  margin-bottom: 35px;

}

.go-to-sistema {
  margin-left: 35px;
}

.lista-filiais {
  margin-top: 30px;

  display:flex;
  gap:20px;
  justify-content: flex-start;

  font-weight: 500;

  margin: 0 15px;

  margin-bottom: 30px;
}

.lista-filiais a{
  background-color: #EEE;
  border-radius: 8px;
  padding: 10px;
}

.lista-filiais a:hover{
  background-color: #CCCC;
}

.titulo-filial {
  /* padding-top: 30px; */
  padding-left: 30px;
  padding-bottom: 30px;

  font-size: 1.5em;
  font-weight: 700;

  color: #009240;

}

.p1 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: large;
  font-weight: bold;

  text-transform: uppercase;
}

.td1 {
  padding-right: 50px;
  vertical-align: middle;
}

.td2 {
  font-size: 24px;
  font-weight: bold;

  text-transform: uppercase;
}

.main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  padding: 0px 15px;
}

.main:last-child {
  border: 0px;
}

.container {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;

  background-color: #EEE;
  border-radius: 20px;

  padding: 2em;

  text-transform: uppercase;

  min-width: 580px;
  height: auto;

  flex: 0 0 calc(50% - 1em);
  box-sizing: border-box;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.title {
  color: #009240;
  font-size: 2em;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-weight: bolder;
}

.sub-title {
  color: #BBBBBB;
  text-align: right;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

.sub-title-detail {
  color: #BBBBBB;
  text-align: right;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.table-header th {
  background-color: #d9d9d9;
  color: #6F6F6F;
}

.disciplina {
  text-align: left;
  padding: .4em 1em;
}

path[name="Alunos rendimento abaixo da média"] {
  cursor: pointer !important;
}

td:first-child {
  width: 40%;
}

th,
td {
  border-bottom: 1px solid #d9d9d9;
  font-size: 0.8em;
  text-align: center;
  padding: 2px;
}

th {
  background-color: #f2f2f2;
  /*padding-top: 5px;*/
}

.custom-tooltip {
  max-width: 150px;
  font-size: .8em;
  padding: 1px;
  border-radius: 8px;
  background-color: #EEE;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.ant-modal .ant-modal-content {
  border-radius: 18px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-tbody>tr>td,
:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper tfoot>tr>th,
:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper tfoot>tr>td {
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;

  color: #757575 !important;
  font-size: .9em;

  border-bottom: 1px solid #F0F0F0;
}

th.ant-table-cell {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

td.ant-table-cell:first-child {
  text-align: start;
  white-space: pre;
  padding-left: 5px !important;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
  background: none !important;
}

:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table {
  background-color: transparent;
}

:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-thead>tr>td {
  border-radius: 0;
  color: #757575;
  font-size: .9em;
  background-color: #EFEFEF;

  text-align: center;
  width: 50px;

}

:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  border: 0;
  padding-left: 5px;
  border-radius: 0;
  border-right: 1px solid #DFDFDF;

  text-align: start;
}

:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:nth-child(2) {
  border: 0;
  border-radius: 0;

  border-right: 1px solid #DFDFDF;
}

:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:nth-child(3) {
  border: 0;
  border-radius: 0;
  border-right: 1px solid #DFDFDF;
}

:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:nth-child(4) {
  border: 0;
  border-radius: 0;
  border-right: 1px solid #DFDFDF;
}

:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-thead > tr:last-child > th:nth-child(3){
  border-right: 1px solid #DFDFDF;
}

:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-thead > tr:last-child > th:nth-child(6){
  border-right: 1px solid #DFDFDF;
}

:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-thead > tr:last-child > th:nth-child(9){
  border-right: 1px solid #DFDFDF;
}

:where(.css-dev-only-do-not-override-d4keus).ant-table-wrapper .ant-table-thead > tr:last-child > th:last-child{
  border-right: 0px solid #DFDFDF;
}

.table-striped tbody tr {
  cursor: pointer;
}

:where(.css-d4keus).ant-table-wrapper .ant-table-thead>tr>th, :where(.css-d4keus).ant-table-wrapper .ant-table-tbody>tr>th, :where(.css-d4keus).ant-table-wrapper .ant-table-tbody>tr>td, :where(.css-d4keus).ant-table-wrapper tfoot>tr>th, :where(.css-d4keus).ant-table-wrapper tfoot>tr>td {
  padding:0;
  width:10px;
  white-space: pre-line;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled{
  background: rgba(0, 0, 0, 0.04);
  color: #727272;
  border-color: transparent !important;
}

.custom-cursor {
  cursor: url(./components/Icons/cursor-mao.png), auto;
}
:where(.css-dev-only-do-not-override-d4keus).ant-form-item-with-help .ant-form-item-explain{
  height: 32px !important;
}

.usuario-sem-registros{
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
}