@media screen and (max-width: 600px) {
    body{
        background-color: rgb(246, 246, 246);
    }
    .title {
        font-size: 1.5em;
    }

    .titulo-conteudo {
        display: flex;
        justify-content: space-around;
        
        margin-left: 0;
        margin-top: 15px;
    }

    .main {
        border: 0 !important;

        flex-direction: column;
        justify-content: center;

        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 0 !important;
    }

    .container-logo {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 160px;
    }
    .p1 {
        position: absolute;
        display: flex;
        justify-content: center;
        /* font-size: .9em; */
        left: 0;
        width: 100%;
        top: 10%;
    }
    .container-titulo {
        display: flex;
        padding-left: 0px;
    }

    .titulo-filial {
        display: flex;
        justify-content: center;
        margin-top: 25px;
    }

    .image1 {
        max-width: 150px;
        max-height: 57px;

        margin-right: 0px;

    }

    .go-to-sistema {
        margin-left: 0px;
        margin-right: 15px;
    }

    .container {
        margin: 0;
        /* margin-bottom: 15px; */
        min-width: auto;
        padding: 0.8em;
        padding-bottom: 1em;
    }

    .sub-title {
        font-size: 0.8em !important;
    }

    .sub-title-detail {
        margin-top: 5px;
        font-size: 0.7em !important;
    }

    th,
    td {
        font-size: 0.7em !important;
    }

    .flex-column {
        margin-top: .5em !important;
    }
    td {
        text-align: center;
        border: none;
        border-bottom: 1px solid #d9d9d9;
        position: relative;
        /*padding-left: 50%;*/
    }

    td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
    }

    .topo1-container{
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        height: 100% !important;
    }
    
    .navbar-menu{
        border-radius: 0 !important;

        width: 100% !important;
        min-width: 100% !important;
        
        position: initial !important;
    }

    .pie-chart{
        font-size: 1.8em !important;
    }

    .menu-arrow{
        width: 20% !important;
    }

    .pie-title-menu{
        flex-direction: row-reverse;
    }

    .hidden-menu{
        display: none !important;
    }

    .filtro.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
        display:flex;
        justify-content: center;
        align-items: center;

        max-height: 40px;
        height: 40px !important;
        width: 80px;
    }

    th.ant-table-cell {
        padding-left: 1px !important;
        padding-right: 1px !important;
      }

      td.ant-table-cell:first-child {
        text-align: start;
        white-space: unset;
        padding-left: 5px !important;
      }
      
      :where(.css-dev-only-do-not-override-d4keus).ant-modal .ant-modal-content {
        padding: 24px 12px;
      }

}

@media screen and (min-width: 601px) and (max-width: 1200px) {
    
}

@media screen and (min-width: 601px) and (max-width: 1195px) {
    .titulo-filial {
        display: flex;
        justify-content: center;
    }

   
}